[type='text'],
[type='password'],
[type='email'],
[type='number'],
[type='url'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='time'],
[type='search'],
[type='tel'],
[type='checkbox'],
[type='radio'],
[multiple],
textarea,
select {
  @apply border shadow-sm bg-white focus:ring focus:ring-opacity-50 placeholder:italic;
}

[type='checkbox'],
[type='radio'] {
  @apply size-4 !important;
}

label.required:after {
  content: "*";
}

[type='radio'] ~ label.required:after {
  content: "" !important;
}

.form-orange {
  [type='text'],
  [type='password'],
  [type='email'],
  [type='number'],
  [type='url'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='week'],
  [type='time'],
  [type='search'],
  [type='tel'],
  [type='checkbox'],
  [type='radio'],
  [multiple],
  textarea,
  select {
    @apply border-orange-200 focus:border-orange-300 focus:ring-orange-200 text-orange-950;
  }

  [type='checkbox'],
  [type='radio'] {
    @apply border-orange-300 valid:focus:ring-orange-500 text-orange-600 !important;
  }
}

.form-blue {
  [type='text'],
  [type='password'],
  [type='email'],
  [type='number'],
  [type='url'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='week'],
  [type='time'],
  [type='search'],
  [type='tel'],
  [type='checkbox'],
  [type='radio'],
  [multiple],
  textarea,
  select {
    @apply border-gray-100 focus:border-blue-300 focus:ring-blue-200 text-blue-900;
  }

  [type='checkbox'],
  [type='radio'] {
    @apply border-gray-300 valid:focus:ring-blue-500 text-blue-600 !important;
  }
}
