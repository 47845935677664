@import "swiper/swiper";

:root {
  --swiper-navigation-color: white;
}

.custom-swiper-button-prev,
.custom-swiper-button-next {
  width: 64px;
  height: 64px;
  margin-top: calc(0px - (64px / 2));
  top: 50%;
  @apply absolute z-10 flex items-center justify-center cursor-pointer rounded-full text-gray-500 bg-white/80 hover:bg-white;
}

.custom-swiper-button-prev.custom-swiper-button-disabled,
.custom-swiper-button-next.custom-swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.custom-swiper-button-prev:after,
.custom-swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}

.custom-swiper-button-prev,
.swiper-container-rtl .custom-swiper-button-next {
  left: 10px;
  right: auto;
}

.custom-swiper-button-prev:after,
.swiper-container-rtl .custom-swiper-button-next:after {
  content: 'prev';
}

.custom-swiper-button-next,
.swiper-container-rtl .custom-swiper-button-prev {
  right: 10px;
  left: auto;
}

.custom-swiper-button-next:after,
.swiper-container-rtl .custom-swiper-button-prev:after {
  content: 'next';
}

.custom-swiper-button-prev.custom-swiper-button-white,
.custom-swiper-button-next.custom-swiper-button-white {
  --swiper-navigation-color: #ffffff;
}

.custom-swiper-button-prev.custom-swiper-button-black,
.custom-swiper-button-next.custom-swiper-button-black {
  --swiper-navigation-color: #000000;
}

.custom-swiper-button-lock {
  display: none;
}

.swiper-slide {
  height: auto;
}

.swiper-button-prev-claims,
.swiper-button-next-claims {
  &.swiper-button-disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
  }
}
