.fade-enter-active, .fade-leave-active {
    transition: opacity 500ms;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}

.fade-enter-to, .fade-leave-from {
    opacity: 1;
}
