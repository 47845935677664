.st-tree {
  .st-tree__icon {
    line-height: 1;
    @apply inline-block w-4 text-center cursor-pointer text-lg;
  }

  li.st-tree__node--closed > ul {
    display: none;
  }

  li:not(.st-tree__node--closed) > .st-tree__icon:before {
    content: '-';
  }

  li.st-tree__node--closed > .st-tree__icon:before {
    content: '+';
  }

  li.st-tree__node--leaf > .st-tree__icon {
    visibility: hidden;
  }
}
