.uc-embedding-container {
  @apply max-h-full rounded-md bg-gray-100 min-h-0;

  .uc-embedding-wrapper {
    @apply rounded-md shadow text-xs;
    @apply font-sans !important;

    .description-text {
      @apply text-gray-500;
    }

    span, a {
      @apply text-gray-400;
    }

    a {
      @apply hover:underline;
    }
  }

  .uc-embedding-buttons {
    @apply mb-2;

    .uc-embedding-more-info {
      @apply rounded-md bg-gray-200 hover:bg-gray-300 text-gray-700;
    }

    .uc-embedding-accept {
      @apply rounded-md bg-blue-600 hover:bg-blue-700;
    }

  }
}

.uc-embed {
  .uc-checkbox {
    input {
      @apply mr-1.5 align-baseline rounded disabled:opacity-50;
    }
  }
}

.h-full {
  .uc-embedding-container {
    height: 100% !important;
  }
}
