@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "forms.scss";
@import "transitions.css";
@import "tree.scss";
@import "tour.scss";
@import "_usercentrics.scss";
/*@import "swiper.scss";*/

html,
body {
    height: 100vh; /* fallback for Js load */
    height: var(--doc-height);
}

[v-cloak] {
    display: none;
}

summary::marker {
    @apply text-gray-500;
}

.mapboxgl-popup-close-button {
    @apply text-2xl p-2 text-gray-400;
}

@layer base {
    :root {
        --swiper-theme-color: rgb(51, 153, 204);
        --swiper-navigation-color: rgb(51, 153, 204);
        --doc-height: 100%;
    }
}

@layer components {
    .btn {
        @apply
        border
        rounded-md
        py-2
        px-6
        flex
        items-center
        justify-center
        text-sm
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        gap-x-2
        w-max
        disabled:opacity-50
        transition-colors
        bg-gradient-to-br
        font-semibold
    }

    .btn-lg {
        @apply
        px-7
        text-base
        select-none
        gap-x-2.5 !important
    }

    .btn-sm {
        @apply
        py-[0.25rem]
        px-[0.75rem]
        gap-x-1 !important
    }

    a.btn {
        text-decoration: none;
    }

    .btn-blue {
        @apply btn
        text-white
        border-blue-500
        bg-blue-500
        from-blue-400
        to-blue-500
        enabled:hover:from-blue-500
        enabled:hover:to-blue-600
        focus:ring-blue-400
    }

    a.btn-blue {
        @apply
        hover:from-blue-500
        hover:to-blue-600
    }

    .btn-outline-blue {
        @apply btn
        border
        border-blue-500
        text-blue-600
        bg-white
        enabled:hover:from-white
        enabled:hover:to-blue-500/20
        enabled:hover:text-blue-700
        focus:ring-blue-400
    }

    a.btn-outline-blue {
        @apply
        hover:from-white
        hover:to-blue-500/20
        hover:text-blue-700
    }

    .btn-green {
        @apply btn
        text-white
        border-green-600
        bg-green-600
        from-green-500
        to-green-600
        enabled:hover:from-green-600
        enabled:hover:to-green-700
        focus:ring-green-500
    }

    a.btn-green {
        @apply
        hover:from-green-600
        hover:to-green-700
    }

    .btn-outline-green {
        @apply btn
        border
        border-green-600
        text-green-700
        bg-white
        enabled:hover:from-white
        enabled:hover:to-green-600/20
        enabled:hover:text-green-800
        focus:ring-green-500
    }

    a.btn-outline-green {
        @apply
        hover:from-white
        hover:to-green-600/20
        hover:text-green-800
    }

    .btn-red {
        @apply btn
        text-white
        border-red-600
        bg-red-600
        from-red-500
        to-red-600
        enabled:hover:border-red-700
        enabled:hover:from-red-600
        enabled:hover:to-red-700
        focus:ring-red-600;
    }

    a.btn-red {
        @apply
        hover:border-red-700
        hover:from-red-500
        hover:to-red-600
    }

    .btn-outline-red {
        @apply btn
        border-red-600
        text-red-600
        bg-white
        enabled:hover:from-white
        enabled:hover:to-red-500/10
        enabled:hover:text-red-700
        focus:ring-red-600
    }

    a.btn-outline-red {
        @apply
        hover:from-white
        hover:to-red-500/10
        hover:text-red-700
    }

    .btn-orange {
        @apply btn
        text-white
        bg-orange-400
        from-orange-400
        to-orange-500
        border-orange-500
        enabled:hover:border-orange-500
        enabled:hover:from-orange-500
        enabled:hover:to-orange-600
        focus:ring-orange-400
    }

    a.btn-orange {
        @apply
        hover:border-orange-500
        hover:from-orange-500
        hover:to-orange-600
    }

    .btn-outline-orange {
        @apply btn
        text-orange-600
        bg-white
        border-orange-500
        enabled:hover:from-white
        enabled:hover:to-orange-500/20
        enabled:hover:text-orange-700
        focus:ring-orange-500
    }

    a.btn-outline-orange {
        @apply
        hover:from-white
        hover:to-orange-500/20
        hover:text-orange-700
    }

    .btn-gray {
        @apply btn
        border-gray-300
        text-gray-600
        bg-gray-200
        focus:ring-gray-300
        from-gray-100
        to-gray-200
        enabled:hover:from-gray-200
        enabled:hover:to-gray-300
    }

    a.btn-gray {
        @apply
        hover:from-gray-200
        hover:to-gray-300
    }

    .btn-outline-gray {
        @apply btn
        border
        border-gray-300
        text-gray-500
        bg-white
        enabled:hover:from-gray-50
        enabled:hover:to-gray-100
        enabled:hover:text-gray-600
        focus:ring-gray-300
    }

    a.btn-outline-gray {
        @apply
        hover:from-gray-50
        hover:to-gray-100
        hover:text-gray-600
    }

    .btn-block {
        @apply w-full !important;
    }

    .btn-link {
        @apply text-lg text-gray-500 enabled:hover:text-gray-700 hover:underline;
        @apply p-0 !important;
    }

    .h1 {
        @apply text-2xl font-medium font-serif leading-tight text-gray-900
    }

    div[data-autocomplete-target] .active {
        @apply bg-gray-100;
    }

    .card {
        @apply shadow-md rounded-md bg-white;
    }

    .bg-global {
        @apply bg-blue-50;
    }
}

@layer utilities {
    .group-card:hover .group-card-hover\:opacity-100 {
        @apply opacity-100;
    }
}

.arrow-transition {
    transition: transform 300ms;
}

.arrow-rotate {
    transform: rotateX(0.5turn);
}

details > summary.list-none::-webkit-details-marker,
details > summary.list-none::marker {
    display: none !important;
}

.prose img + figcaption {
    @apply -mt-6;
}

/*#smooth-wrapper {*/
/*    @apply overflow-hidden fixed h-full w-full inset-0;*/
/*}*/

/*#smooth-content {*/
/*    @apply overflow-auto w-full;*/
/*    !* set a height because the contents are position: absolute, thus natively there's no height *!*/
/*    !*height: 16000px;*!*/
/*	!*height: 100%;*!*/
/*}*/

::backdrop {
    @apply sm:bg-gray-900/75 sm:[@supports(backdrop-filter:blur(0))]:bg-gray-900/50 sm:[@supports(backdrop-filter:blur(0))]:backdrop-blur-sm sm:transition-opacity;
}

.flatpickr-day.startRange,
.flatpickr-day.endRange {
    @apply text-orange-950 font-semibold bg-orange-500 border-orange-600 !important;
}

.flatpickr-day.startRange {
    @apply rounded-l-xl !important;
}

.flatpickr-day.endRange {
    @apply rounded-r-xl !important;
}

.flatpickr-day.inRange {
    @apply bg-orange-300 border-transparent shadow-none !important;
}

.flatpickr-current-month span.cur-month:hover {
    @apply bg-transparent !important;
}

.flatpickr-month {
    @apply text-orange-950 !important;
}

.flatpickr-weekday {
    @apply text-orange-900 !important;
}

.flatpickr-day:not(.flatpickr-disabled) {
    @apply text-orange-900 !important;
}

.flatpickr-day:hover:not(.flatpickr-disabled) {
    @apply bg-orange-100 !important;
}
