#reiseverlauf {
  .mapboxgl-scroll-zoom-blocker {
    display: none !important;
  }

  .chapter-aufenthalt + .chapter-aufenthalt {
    margin-top: 1rem;
  }

  .chapter-strecke + .chapter-strecke {
    border-top: 1px solid rgb(229, 231, 235);
  }
}
